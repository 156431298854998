@import '~antd/dist/antd.css';

@import "src/scss/basic/spacing";
@import "src/scss/basic/basic";

#root {
  min-height: 100%;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  min-height: 100vh;
}

.white-color {
  color: #FFF;
}

.font-size-26 {
  font-size: 26px;
}